import React, { useEffect, useState } from 'react'

import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { api } from 'api'
import PropTypes from 'prop-types'
import { BarChart, Bar, Tooltip, ResponsiveContainer, Cell, YAxis } from 'recharts'

import { StyledCard } from './styles'

const getFillForStatus = (status) => {
  switch (status) {
    case 'Pendentes convidado':
      return '#FFD700'
    case 'Pendentes':
      return '#FF9800'
    case 'Confirmadas':
      return '#2196F3'
    case 'Pagas':
      return '#89AB15'
    case 'Terminadas':
      return '#4CAF50'
    case 'Canceladas':
      return '#F44336'
    case 'Expiradas':
      return '#D32F2F'
    case 'Faltas':
      return '#E91E63'
    default:
      return ''
  }
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledCard style={{ height: 'auto' }}>
        <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
      </StyledCard>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
}

const AppointmentsByStatus = () => {
  const [loading, setLoading] = useState(false)
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    setLoading(true)
    api.get('/appointments/status').then(({ data }) => {
      setLoading(false)
      setStatuses(data)
    })
  }, [])

  return (
    <StyledCard container direction="row">
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Typography variant="subtitle1" gutterBottom>
          Marcações por estado
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '100%', paddingBottom: 10 }}>
        {loading && <CircularProgress style={{ marginTop: 20 }} />}
        {!loading && (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={400}
              data={statuses}
              margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 15,
              }}
            >
              <Tooltip content={<CustomTooltip />} cursor={{ opacity: 0.1 }} />
              <YAxis />
              <Bar dataKey="number" radius={[10, 10, 0, 0]}>
                {statuses.map((entry, index) => (
                  <Cell fill={getFillForStatus(entry.name)} key={`cell-${index}`} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      </Grid>
    </StyledCard>
  )
}

export default AppointmentsByStatus
