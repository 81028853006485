import React from 'react'
import { useHistory } from 'react-router-dom'

import MUIDataTable from 'mui-datatables'
import PropTypes from 'prop-types'

import { AvatarName, StyledAvatar, StyledChip } from './styles'

const UserTable = ({ users, search }) => {
  const history = useHistory()
  const columns = [
    {
      name: 'Foto',
      options: {
        sort: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <StyledAvatar alt={users[dataIndex].name} src={users[dataIndex].photo_url}>
              <AvatarName variant="subtitle1">
                {`${users[dataIndex].name[0]}${
                  users[dataIndex].name.split(' ').length > 1
                    ? users[dataIndex].name.split(' ')[1][0]
                    : ''
                }`}
              </AvatarName>
            </StyledAvatar>
          )
        },
      },
    },
    {
      name: 'name',
      label: 'Nome',
      options: {},
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const val1 = Number(obj1.data)
            const val2 = Number(obj2.data)
            return (val1 - val2) * (order === 'asc' ? 1 : -1)
          }
        },
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <StyledChip
            status={users[dataIndex].status ? 'true' : 'false'}
            label={users[dataIndex].status ? 'Ativo' : 'Pendente'}
          />
        ),
      },
    },
    {
      name: 'visit_count',
      label: 'Nº Visitas',
      options: {},
    },
    {
      name: 'earnings',
      label: 'Ganhos (€)',
      options: {},
    },
    {
      name: 'last_visit',
      label: 'Última Visita',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) =>
          users[dataIndex].last_visit ? users[dataIndex].last_visit : '',
      },
    },
    {
      name: 'created_at',
      label: 'Inscrito desde',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => users[dataIndex].created_at,
      },
    },
  ]

  const options = {
    filter: false,
    download: false,
    print: false,
    search: false,
    searchText: search,
    customSearchRender: () => null,
    viewColumns: false,
    onRowClick: (rowData, rowMeta) =>
      history.push(`/users/${users[rowMeta.dataIndex].id}/info`),
    sortOrder: { name: 'Nome', direction: 'desc' },
    rowsPerPageOptions: [10, 20, 50],
    selectableRows: 'none',
    responsive: 'standard',
    textLabels: {
      body: {
        noMatch: 'Não foram encontrados resultados',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column) => `Ordenar ${column.label}`,
      },
      pagination: {
        next: 'Próxima',
        previous: 'Anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Pesquisar',
        downloadCsv: 'Descarregar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar Tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESET',
      },
      viewColumns: {
        title: 'Ver Colunas',
        titleAria: 'Ver/Esconder Colunas da Tabela',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Eliminar',
        deleteAria: 'Eliminar linhas selecionadas',
      },
    },
  }

  return <MUIDataTable data={users} columns={columns} options={options} />
}

UserTable.propTypes = {
  users: PropTypes.array,
  search: PropTypes.string,
}
UserTable.defaultProps = {
  users: [],
  search: '',
}

export default UserTable
