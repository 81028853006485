import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import TemplatesEditDialog from 'components/Vouchers/Templates/EditDialog'
import TemplatesNewDialog from 'components/Vouchers/Templates/NewDialog'
import parseErrorMessage from 'helpers/parse-error-message'
import { StateContext } from 'state'

import {
  UploadLabel,
  StyledTextField,
  StyledCardMedia,
  StyledCard,
  StyledCardContent,
  PlusIconWrapper,
  StyledChip,
} from './styles'

const VoucherTemplates = () => {
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [templates, setTemplates] = useState(null)
  const [newFile, setNewFile] = useState(null)
  const [openNew, setOpenNew] = useState(false)
  const [editTemplate, setEditTemplate] = useState(null)
  const [openEdit, setOpenEdit] = useState(false)

  useEffect(() => {
    api
      .get('/voucher-templates/list')
      .then(({ data }) => {
        setTemplates(data)
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setOpenNew(!!newFile)
  }, [newFile])

  useEffect(() => {
    setOpenEdit(!!editTemplate)
  }, [editTemplate])

  const handleFileUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0]
      event.target.value = ''

      let reader = new FileReader()
      reader.onloadend = () => {
        setNewFile({ file, preview: reader.result })
      }
      reader.readAsDataURL(file)
    }
  }

  if (!templates) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container alignItems="center" style={{ marginBottom: 15 }}>
        <IconButton onClick={() => history.goBack()} aria-label="go-back">
          <LeftArrowAlt size={30} />
        </IconButton>
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: 600, marginLeft: 10 }}
        >
          Templates de Vouchers
        </Typography>
      </Grid>
      <Grid container direction="column" alignItems="center">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6} lg={4}>
            <UploadLabel htmlFor="file">
              <input
                hidden
                id="file"
                name="file"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={handleFileUpload}
              />
              <ButtonBase component="span" style={{ width: '100%', height: '100%' }}>
                <StyledCard variant="outlined" style={{ textAlign: 'center' }}>
                  <StyledCardContent>
                    <PlusIconWrapper container justify="center" alignItems="center">
                      <Plus size={45} />
                    </PlusIconWrapper>
                    <Typography variant="h6" gutterBottom style={{ fontWeight: 500 }}>
                      Inserir Template
                    </Typography>
                  </StyledCardContent>
                </StyledCard>
              </ButtonBase>
            </UploadLabel>
          </Grid>
          {templates.map((template, i) => (
            <Grid key={template.id} item xs={12} sm={6} lg={4}>
              <Card variant="outlined">
                <CardActionArea
                  onClick={() => setEditTemplate(template)}
                  style={{ height: '100%' }}
                >
                  <StyledCardMedia image={template.image_url} title={template.title} />
                  <CardContent style={{ paddingBottom: 12 }}>
                    <StyledTextField
                      disabled
                      fullWidth
                      variant="filled"
                      name="title"
                      label="Título"
                      value={template.title}
                    />
                    <Grid
                      container
                      alignItems="center"
                      justify="space-between"
                      style={{ marginTop: 12 }}
                    >
                      <StyledChip
                        status={template.status.toString()}
                        label={template.status ? 'Ativo' : 'Inativo'}
                      />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          color="secondary"
                          style={{ marginRight: 5 }}
                        >
                          <b>{template.vouchers}</b>
                        </Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          vouchers
                        </Typography>
                      </div>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <TemplatesNewDialog open={openNew} setFile={setNewFile} file={newFile} />
      <TemplatesEditDialog
        open={openEdit}
        setTemplate={setEditTemplate}
        template={editTemplate}
      />
    </Container>
  )
}

export default VoucherTemplates
