import React, { useState, useEffect, useContext } from 'react'

import {
  Tabs,
  Tab,
  Grid,
  Typography,
  CardActionArea,
  DialogContent,
  Button,
  DialogActions,
  InputAdornment,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Container,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import { ArrowLeftShort } from '@styled-icons/bootstrap/ArrowLeftShort'
import { ArrowRightShort } from '@styled-icons/bootstrap/ArrowRightShort'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api, publicApi } from 'api'
import { ReactComponent as UserFemaleIcon } from 'assets/icons/female-user.svg'
import { ReactComponent as UserMaleIcon } from 'assets/icons/male-user.svg'
import Dialog from 'components/shared/Dialog'
import LoadingPage from 'components/shared/LoadingPage'
import IOSSwitch from 'components/shared/Switch'
import Card from 'components/Testimonials/Card'
import Steps from 'components/Testimonials/Steps'
import { ContainerCard } from 'pages/styles'
import { StyledTextField } from 'pages/Users/List/styles'
import { StateContext } from 'state'

import {
  StyledCard,
  NewTestimonyContent,
  NewTestimonyIcon,
  StyledFormControlLabel,
  TextArea,
  UserAvatar,
  StyledListItem,
} from './styles'

const Testimonials = () => {
  const { actions } = useContext(StateContext)
  const [testimonials, setTestimonials] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const [openEdit, setOpenEdit] = useState(false)
  const [openCreate, setOpenCreate] = useState(false)
  const [loading, setLoading] = useState(true)
  const [edit, setEdit] = useState(null)
  const [createUser, setCreateUser] = useState(null)
  const [searchUser, setSearchUser] = useState('')
  const [users, setUsers] = useState([])
  const [editTestimonyText, setEditTestimonyText] = useState('')
  const [newTestimonyText, setNewTestimonyText] = useState('')
  const [editTestimonyOptions, setEditTestimonyOptions] = useState({
    showPhoto: false,
    status: false,
  })
  const [newTestimonyOptions, setNewTestimonyOptions] = useState({
    showPhoto: false,
    status: false,
  })
  const [textLimit, setTextLimit] = React.useState(201)
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    api.get('/testimonies/list').then(({ data }) => {
      setTestimonials(data)
      setLoading(false)
    })
  }, [])

  const handleChange = (e, newValue) => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    setOpenEdit(!!edit)
  }, [edit])

  const handleOpenEdit = (testimony) => {
    setEdit(testimony)
    setEditTestimonyText(testimony.testimony)
    setTextLimit(201 - testimony.testimony.length)
    setEditTestimonyOptions({
      showPhoto: testimony.show_photo,
      status: testimony.status,
    })
  }

  const handleOpenCreate = () => {
    if (users.length === 0) {
      api.get('/users?filter=testimonies').then(({ data }) => setUsers(data.users))
    }
    setOpenCreate(true)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setTimeout(() => {
      setEditTestimonyText('')
      setTextLimit(201)
      setEdit(null)
    }, 300)
  }

  const handleCloseCreate = () => {
    setOpenCreate(false)
    setCreateUser(null)
  }

  const handleEditSwitchChange = (event) => {
    setEditTestimonyOptions({
      ...editTestimonyOptions,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCreateSwitchChange = (event) => {
    setNewTestimonyOptions({
      ...newTestimonyOptions,
      [event.target.name]: event.target.checked,
    })
  }

  const handleSaveEdit = () => {
    if (editTestimonyText.trim().length <= 0) return
    if (editTestimonyText.trim().length > 201) {
      actions.notification.setNotification(
        'error',
        'O testemunho não deve ultrapassar os 201 caracteres.'
      )
      return
    }
    const data = {
      testimony: editTestimonyText,
      show_photo: editTestimonyOptions.showPhoto,
      status: editTestimonyOptions.status,
    }
    const testimonyId = edit.id
    // optimist update
    const updated = [...testimonials]
    const index = updated.findIndex((x) => x.id === testimonyId)
    updated[index].testimony = data.testimony
    updated[index].status = data.status
    updated[index].show_photo = data.show_photo
    setTestimonials(updated)

    api
      .patch(`/testimonies/${testimonyId}`, data)
      .then(({ data }) => {
        if (data.testimony) {
          actions.notification.setNotification(
            'success',
            'Testemunho atualizado com sucesso'
          )
        } else {
          actions.notification.setNotification(
            'error',
            'Não foi possível retornar o testemunho atualizado'
          )
        }
        actions.global.getTestimonialsCount()
      })
      .catch((e) => {
        actions.notification.setNotification('error', `Ocorreu um erro: ${e}`)
      })

    handleCloseEdit()
  }

  const handleSaveCreate = () => {
    if (newTestimonyText.trim().length <= 0 || !createUser?.email) return
    if (newTestimonyText.trim().length > 201) {
      actions.notification.setNotification(
        'error',
        'O testemunho não deve ultrapassar os 201 caracteres.'
      )
      return
    }
    const data = {
      testimony: newTestimonyText,
      show_photo: newTestimonyOptions.showPhoto,
      status: newTestimonyOptions.status,
      email: createUser.email,
    }

    setNewTestimonyText('')
    setTextLimit(201)
    setCreateUser(null)
    setNewTestimonyOptions({
      showPhoto: false,
      status: false,
    })

    publicApi
      .post('/testimonies', data)
      .then(({ data }) => {
        if (data.testimony) {
          setTestimonials([data.testimony, ...testimonials])
          actions.notification.setNotification('success', 'Testemunho criado com sucesso')
        } else {
          actions.notification.setNotification(
            'error',
            'Não foi possível retornar o novo testemunho'
          )
        }
      })
      .catch((e) => {
        actions.notification.setNotification('error', `Ocorreu um erro: ${e}`)
      })

    handleCloseCreate()
  }

  const handleNewTestimonyTextChange = (event) => {
    if (event.target.value.length <= 201) {
      setNewTestimonyText(event.target.value)
      setTextLimit(201 - event.target.value.length)
    } else {
      event.preventDefault()
    }
  }

  const handleEditTestimonyTextChange = (event) => {
    if (event.target.value.length <= 201) {
      setEditTestimonyText(event.target.value)
      setTextLimit(201 - event.target.value.length)
    } else {
      event.preventDefault()
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <ContainerCard style={{ padding: 0 }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ height: 80, paddingTop: 20 }}
        >
          <Tab
            style={{ textTransform: 'none' }}
            disableRipple
            disableFocusRipple
            label="Todos"
          />
          <Tab
            style={{ textTransform: 'none' }}
            disableRipple
            disableFocusRipple
            label="Pendentes"
          />
          <Tab
            style={{ textTransform: 'none' }}
            disableRipple
            disableFocusRipple
            label="Publicados"
          />
        </Tabs>
      </ContainerCard>
      <div style={{ margin: '20px 20px 0 20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard variant="outlined" style={{ textAlign: 'center' }}>
              <CardActionArea onClick={handleOpenCreate} style={{ height: '100%' }}>
                <NewTestimonyContent>
                  <NewTestimonyIcon container justify="center" alignItems="center">
                    <Plus size={45} />
                  </NewTestimonyIcon>
                  <Typography variant="h6" gutterBottom style={{ fontWeight: 500 }}>
                    Inserir Testemunho
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Caso algum cliente tenha realizado pelo menos uma marcação e não fez
                    um testemunho, podes pedir na mesma à pessoa e inserir por essa pessoa
                    aqui.
                  </Typography>
                </NewTestimonyContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
          {(currentTab === 0
            ? testimonials
            : currentTab === 1
            ? testimonials.filter((item) => !item.status)
            : testimonials.filter((item) => item.status)
          ).map((testimony) => (
            <Card
              key={testimony.id}
              testimony={testimony}
              handleOpenEdit={handleOpenEdit}
            />
          ))}
        </Grid>
      </div>
      {/* Edit dialog */}
      <Dialog
        open={openEdit}
        handleClose={() => handleCloseEdit()}
        title="Editar testemunho"
        onSave={handleSaveEdit}
      >
        <DialogContent style={{ textAlign: 'right' }}>
          <Grid container alignItems="center" style={{ marginBottom: 15 }}>
            <UserAvatar alt={edit?.user?.name} src={edit?.user?.photo_url}>
              {edit?.user?.gender &&
                (edit?.user?.gender === 'M' ? <UserMaleIcon /> : <UserFemaleIcon />)}
              {edit?.user && !edit?.user?.gender && (
                <PermIdentityIcon color="primary" style={{ fontSize: 32 }} />
              )}
              {!edit?.user && <CloseIcon color="primary" style={{ fontSize: 32 }} />}
            </UserAvatar>
            <Typography variant="subtitle1">
              <span style={{ fontWeight: 500 }}>
                {edit?.user ? edit.user?.name : 'Cliente removido'}
              </span>{' '}
              disse:
            </Typography>
          </Grid>
          <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
            Tens <b>{textLimit}</b> caracteres restantes
          </Typography>
          <TextArea
            variant="outlined"
            fullWidth
            multiline
            disabled={!edit?.user}
            value={editTestimonyText}
            onChange={handleEditTestimonyTextChange}
          />
          <StyledFormControlLabel
            control={
              <IOSSwitch
                inputProps={{ autoFocus: true }}
                checked={editTestimonyOptions.showPhoto}
                onChange={handleEditSwitchChange}
                name="showPhoto"
              />
            }
            labelPlacement="start"
            label="Mostrar foto (com permissão do cliente)"
          />
          <StyledFormControlLabel
            control={
              <IOSSwitch
                checked={editTestimonyOptions.status}
                onChange={handleEditSwitchChange}
                name="status"
              />
            }
            labelPlacement="start"
            label="Publicar na página inicial do site"
          />
        </DialogContent>
      </Dialog>
      {/* Create dialog */}
      <Dialog
        open={openCreate}
        handleClose={handleCloseCreate}
        title="Novo testemunho"
        onSave={activeStep === 1 ? handleSaveCreate : null}
      >
        <DialogContent>
          <Steps active={activeStep} handleChange={() => {}} />
          {activeStep === 0 && (
            <Grid container direction="column">
              <Grid item xs={12} style={{ margin: '10px 0 15px 0' }}>
                <StyledTextField
                  placeholder="Procura pelo nome"
                  variant="filled"
                  fullWidth
                  value={searchUser}
                  onChange={(e) => setSearchUser(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={27} />
                      </InputAdornment>
                    ),
                    endAdornment: searchUser.trim().length > 0 && (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setSearchUser('')}
                          label="clear-search"
                        >
                          <CloseOutline size={27} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} style={{ overflowY: 'auto' }}>
                <List>
                  {users
                    .filter(
                      (usr) =>
                        searchUser.trim().length <= 0 ||
                        usr.name.toLowerCase().includes(searchUser.toLowerCase())
                    )
                    .map((user) => (
                      <StyledListItem
                        button
                        onClick={() => setCreateUser(user)}
                        selected={user.email === createUser?.email}
                        key={user.id}
                      >
                        <ListItemAvatar>
                          <UserAvatar alt={user.name} src={user.photo}>
                            {user.gender &&
                              (user.gender === 'M' ? (
                                <UserMaleIcon />
                              ) : (
                                <UserFemaleIcon />
                              ))}
                            {!user.gender && (
                              <PermIdentityIcon
                                color="primary"
                                style={{ fontSize: 32 }}
                              />
                            )}
                          </UserAvatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.name} secondary={user.locality} />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" color="textSecondary">
                            {user.visit_count} visitas
                          </Typography>
                        </ListItemSecondaryAction>
                      </StyledListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <>
              <Grid container alignItems="center" style={{ marginBottom: 15 }}>
                <UserAvatar alt={createUser?.name} src={createUser?.photo}>
                  {createUser?.gender === 'M' ? <UserMaleIcon /> : <UserFemaleIcon />}
                </UserAvatar>
                <Typography variant="subtitle1">
                  <span style={{ fontWeight: 500 }}>{createUser?.name}</span> disse:
                </Typography>
              </Grid>
              <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                Tens <b>{textLimit}</b> caracteres restantes
              </Typography>
              <TextArea
                variant="outlined"
                fullWidth
                multiline
                value={newTestimonyText}
                onChange={handleNewTestimonyTextChange}
              />
              <StyledFormControlLabel
                control={
                  <IOSSwitch
                    inputProps={{ autoFocus: true }}
                    checked={newTestimonyOptions.showPhoto}
                    onChange={handleCreateSwitchChange}
                    name="showPhoto"
                  />
                }
                labelPlacement="start"
                label="Mostrar foto (com permissão do cliente)"
              />
              <StyledFormControlLabel
                control={
                  <IOSSwitch
                    checked={newTestimonyOptions.status}
                    onChange={handleCreateSwitchChange}
                    name="status"
                  />
                }
                labelPlacement="start"
                label="Publicar na página inicial do site"
              />
            </>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 12, justifyContent: 'center' }}>
          <Button
            color="primary"
            size="large"
            disabled={activeStep === 0 && !createUser}
            variant="text"
            onClick={() => setActiveStep((prev) => (prev === 0 ? prev + 1 : prev - 1))}
            startIcon={activeStep === 1 ? <ArrowLeftShort size={25} /> : null}
            endIcon={activeStep === 0 ? <ArrowRightShort size={25} /> : null}
          >
            {activeStep === 0 ? 'Próximo' : 'Anterior'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Testimonials
