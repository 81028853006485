import React, { useState } from 'react'

import { Container, Grid } from '@material-ui/core'
import AppointmentList from 'components/Dashboard/AppointmentList'
import AppointmentsByStatus from 'components/Dashboard/AppointmentsByStatus'
import AppointmentsQuickStats from 'components/Dashboard/AppointmentsQuickStats'
import LatestAppointments from 'components/Dashboard/LatestAppointments'
import NewUsers from 'components/Dashboard/NewUsers'
import UsersAndGuests from 'components/Dashboard/UsersAndGuests'
import UsersQuickStats from 'components/Dashboard/UsersQuickStats'
import UsersRanking from 'components/Dashboard/UsersRanking'

import { Tab, Title } from './styles'

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container direction="column">
        <Title variant="h3" color="secondary" gutterBottom>
          Dashboard
        </Title>
        <Grid item style={{ marginTop: 10 }}>
          <Grid container>
            <Grid item xs={6} sm="auto" style={{ textAlign: 'center' }}>
              <Tab
                variant="subtitle1"
                color="primary"
                active={activeTab === 0 ? 'true' : 'false'}
                onClick={() => setActiveTab(0)}
                style={{ marginRight: 25 }}
              >
                Marcações
              </Tab>
            </Grid>
            <Grid item xs={6} sm="auto" style={{ textAlign: 'center' }}>
              <Tab
                variant="subtitle1"
                color="primary"
                active={activeTab === 1 ? 'true' : 'false'}
                onClick={() => setActiveTab(1)}
              >
                Clientes
              </Tab>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: 20 }}>
          {activeTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
                <AppointmentList />
              </Grid>
              <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'center' }}>
                <AppointmentsQuickStats />
              </Grid>
              <Grid item xs={12} sm={12} md={5} style={{ textAlign: 'center' }}>
                <LatestAppointments />
              </Grid>
              <Grid item xs={12} sm={12} md={6} style={{ textAlign: 'center' }}>
                <AppointmentsByStatus />
              </Grid>
            </Grid>
          )}
          {activeTab === 1 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={7} sm={6}>
                        <UsersAndGuests />
                      </Grid>
                      <Grid item xs={5} sm={6}>
                        <Grid
                          container
                          direction="column"
                          justify="space-around"
                          style={{ textAlign: 'center', height: 260, overflow: 'hidden' }}
                        >
                          <UsersQuickStats />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <UsersRanking />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <NewUsers />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Dashboard
