import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'

import { Grid } from '@material-ui/core'
import { api } from 'api'
import logo from 'assets/logo.png'
import voucherTitle from 'assets/voucher-title.png'
import LoadingPage from 'components/shared/LoadingPage'
import moment from 'moment'
import QRCode from 'qrcode.react'

import {
  Container,
  CoverBg,
  MaxWidth,
  Area,
  CoverTitle,
  CoverUsers,
  UserLabel,
  UserName,
  Title,
  Hr,
  Code,
  InfoIcon,
  WarningTitle,
  WarningText,
} from './styles'

const VoucherDocument = () => {
  const { id } = useParams()
  const [voucher, setVoucher] = useState(null)

  useEffect(() => {
    if (id) {
      api.get(`/vouchers/${id}/show-admin`).then(({ data }) => {
        setVoucher(data)
        document.title = `Voucher de ${data.buyer_name} ${moment(
          data.created_at,
          'YYYY-MM-DDTHH:mm:ss'
        ).format('DD-MM-YY')}`
      })
    }
  }, [id])

  if (!voucher) {
    return <LoadingPage />
  }

  return (
    <Container>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <MaxWidth>
        <CoverBg>
          <img alt="Capa" src={voucher.template.image_url} />
        </CoverBg>
        <Area>
          <CoverTitle alt="Voucher" src={voucherTitle} />
          <CoverUsers container alignItems="center">
            <Grid item>
              <UserLabel variant="h6">De:</UserLabel>
              {voucher.buyer_name.split(' ').length > 1 ? (
                <UserName variant="h6">{`${voucher.buyer_name.split(' ')[0]} ${
                  voucher.buyer_name.split(' ')[1][0]
                }.`}</UserName>
              ) : (
                <UserName variant="h6">{voucher.buyer_name}</UserName>
              )}
            </Grid>
            <Grid item>
              <UserLabel variant="h6">Para:</UserLabel>
              {voucher.receiver_name.split(' ').length > 1 ? (
                <UserName variant="h6">{`${voucher.receiver_name.split(' ')[0]} ${
                  voucher.receiver_name.split(' ')[1][0]
                }.`}</UserName>
              ) : (
                <UserName variant="h6">{voucher.receiver_name}</UserName>
              )}
            </Grid>
          </CoverUsers>
        </Area>
        <Area>
          <Title variant="h3" secondary="true">
            {voucher.service.name}
          </Title>
          <Hr />
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ marginBottom: 30 }}
          >
            <UserLabel variant="h5">Código a usar:</UserLabel>
            <Code variant="body1">{voucher.code}</Code>
          </Grid>
          <Grid container justify="center" alignItems="center">
            <UserLabel variant="h5">Válido até:</UserLabel>
            <UserName
              variant="h5"
              style={{ textTransform: 'capitalize', fontWeight: 500 }}
            >
              {moment(voucher.expires_at, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY')}
            </UserName>
          </Grid>
        </Area>
        <Area>
          <Grid container justify="center" alignItems="center">
            <InfoIcon size={50} />
            <div style={{ textAlign: 'left' }}>
              <WarningTitle variant="h4" gutterBottom>
                Apenas por marcação
              </WarningTitle>
              <WarningText variant="h5">
                Pode agendar no nosso website ou contactar-nos
              </WarningText>
            </div>
          </Grid>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ width: '80%', margin: '0 auto', marginTop: 60 }}
          >
            <Grid item xs={8} container alignItems="center">
              <img
                src={logo}
                alt="Ana Pinto Terapeuta"
                style={{ width: 75, height: 75 }}
              />
              <div style={{ marginLeft: 20, textAlign: 'left' }}>
                <WarningTitle variant="h4" style={{ fontWeight: 700 }}>
                  ANA PINTO TERAPEUTA
                </WarningTitle>
                <WarningText variant="h5">anapintoterapeuta.pt</WarningText>
              </div>
            </Grid>
            <QRCode value="https://anapintoterapeuta.pt" size={75} fgColor="#505050" />
          </Grid>
        </Area>
      </MaxWidth>
    </Container>
  )
}

export default VoucherDocument
