import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import {
  Button,
  Grid,
  IconButton,
  Typography,
  Link as MuiLink,
  FormControl,
  InputLabel,
  MenuItem,
  Container,
} from '@material-ui/core'
import { ArrowRight } from '@styled-icons/bootstrap/ArrowRight'
import { LeftArrowAlt } from '@styled-icons/boxicons-regular/LeftArrowAlt'
import { DocumentText } from '@styled-icons/ionicons-outline/DocumentText'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import { StyledChip } from 'components/Vouchers/Table/styles'
import parseErrorMessage from 'helpers/parse-error-message'
import parseVoucherStatus from 'helpers/parse-voucher-status'
import moment from 'moment'
import { StateContext } from 'state'

import {
  Theme,
  Hr,
  ServiceName,
  InfosGrid,
  BottomGrid,
  Title,
  MiddleGrid,
  CodeNumber,
  UserGrid,
  StyledLink,
  StyledSelect,
} from './styles'

const statusMap = {
  pending: ['canceled', 'pending', 'paid'],
  paid: ['canceled', 'paid'],
}

const VoucherInfo = () => {
  const { id } = useParams()
  const history = useHistory()
  const { actions } = useContext(StateContext)
  const [voucher, setVoucher] = useState(null)
  const [status, setStatus] = useState(null)
  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (id) {
      api.get(`/vouchers/${id}/show-admin`).then(({ data }) => {
        setVoucher(data)
        setStatus(data.status)
      })
    }
  }, [id])

  if (!voucher) {
    return <LoadingPage />
  }

  const handleSubmit = () => {
    setEdit(false)
    api
      .patch(`/vouchers/${id}`, { status })
      .then(({ data }) => {
        setVoucher(data)
        actions.global.getVouchersCount()
      })
      .catch((e) => {
        const message = parseErrorMessage(e)
        actions.notification.setNotification('error', message)
      })
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid container>
        <IconButton onClick={() => history.goBack()} aria-label="go-back">
          <LeftArrowAlt size={30} />
        </IconButton>
        <Theme
          style={{
            backgroundImage: voucher.template.image_url
              ? `url(${voucher.template.image_url})`
              : 'lightgray',
          }}
        >
          <Grid container direction="column" alignItems="center">
            <Title />
          </Grid>
        </Theme>
        <MiddleGrid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                De:
              </Typography>
              {voucher.buyer?.id ? (
                <MuiLink component={Link} to={`/users/${voucher.buyer?.id}/info`}>
                  <Typography variant="h6" color="primary">
                    {voucher.buyer_name}
                  </Typography>
                </MuiLink>
              ) : (
                <Typography variant="h6" color="secondary">
                  {voucher.buyer_name}
                </Typography>
              )}
              <Typography variant="subtitle1" color="textSecondary">
                {voucher.buyer_email}
              </Typography>
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                Para:
              </Typography>
              {voucher.receiver?.id ? (
                <MuiLink component={Link} to={`/users/${voucher.receiver?.id}/info`}>
                  <Typography variant="h6" color="primary">
                    {voucher.receiver_name}
                  </Typography>
                </MuiLink>
              ) : (
                <Typography variant="h6" color="secondary">
                  {voucher.receiver_name}
                </Typography>
              )}
              <Typography variant="subtitle1" color="textSecondary">
                {voucher.receiver_email}
              </Typography>
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                Criado a:
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{ fontWeight: 600, textTransform: 'capitalize' }}
              >
                {moment(voucher.created_at, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY')}
              </Typography>
            </UserGrid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserGrid container direction="column">
              <Typography variant="body1" color="textSecondary">
                Usado a:
              </Typography>
              {voucher.used_at && (
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  style={{ fontWeight: 600, textTransform: 'capitalize' }}
                >
                  {moment(voucher.used_at, 'YYYY-MM-DDTHH:mm:ss').format('DD MMMM YYYY')}
                </Typography>
              )}
            </UserGrid>
          </Grid>
        </MiddleGrid>
        <BottomGrid container direction="column" alignItems="center">
          <ServiceName variant="h5">{voucher.service.name}</ServiceName>
          <Hr />
          <InfosGrid container justify="center" alignItems="center">
            {edit && statusMap[voucher.status] ? (
              <FormControl variant="filled">
                <InputLabel id="status">Estado</InputLabel>
                <StyledSelect
                  labelId="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusMap[voucher.status].map((stat) => (
                    <MenuItem key={stat} selected={stat === status} value={stat}>
                      {parseVoucherStatus(stat)}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            ) : (
              <StyledChip
                status={voucher.status}
                label={parseVoucherStatus(voucher.status)}
              />
            )}
            <Grid
              item
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              {edit && (
                <>
                  <Button onClick={handleSubmit} variant="contained" color="primary">
                    Submeter
                  </Button>
                  <StyledLink
                    style={{ marginLeft: 10 }}
                    color="secondary"
                    onClick={() => setEdit(false)}
                  >
                    <Typography variant="subtitle2">Cancelar</Typography>
                  </StyledLink>
                </>
              )}
              {!edit && statusMap[voucher.status] && (
                <StyledLink onClick={() => setEdit(true)}>
                  <Typography variant="subtitle1">Alterar</Typography>
                </StyledLink>
              )}
            </Grid>
          </InfosGrid>
          {(voucher.status === 'pending' ||
            voucher.status === 'paid' ||
            voucher.status === 'scheduled') && (
            <Button
              variant="outlined"
              size="large"
              style={{ marginTop: 15 }}
              startIcon={<DocumentText size={23} />}
              color="secondary"
              component={Link}
              to={`/voucher/${id}/document`}
              target="_blank"
            >
              Gerar PDF
            </Button>
          )}
          {(voucher.status === 'pending' || voucher.status === 'paid') && (
            <>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Código:
                </Typography>
                {voucher.code.split('').map((number, i) => (
                  <CodeNumber key={i}>
                    <Typography variant="subtitle1">{number}</Typography>
                  </CodeNumber>
                ))}
              </InfosGrid>
              <InfosGrid container justify="center" alignItems="center">
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  component={Link}
                  to={`/appointments/new?voucher=${voucher.code}`}
                >
                  Agendar <ArrowRight style={{ marginLeft: 5 }} size={23} />
                </Button>
              </InfosGrid>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Válido até:
                </Typography>
                <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                  {moment(voucher.expires_at, 'YYYY-MM-DDTHH:mm:ss').format(
                    'DD MMMM YYYY'
                  )}
                </Typography>
              </InfosGrid>
            </>
          )}
          {status === 'scheduled' && voucher.appointment && (
            <>
              <InfosGrid container justify="center" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  Data da marcação:
                </Typography>
                <Typography variant="subtitle1" style={{ textTransform: 'capitalize' }}>
                  {`${moment(voucher.appointment.date, 'YYYY-MM-DD').format(
                    'DD MMM YYYY'
                  )} ${moment(voucher.appointment.start_time, 'HH:mm:ss').format(
                    'HH:mm'
                  )}`}
                </Typography>
              </InfosGrid>

              <InfosGrid container justify="center" alignItems="center">
                <Button
                  variant="text"
                  color="primary"
                  component={Link}
                  to={`/appointments/${voucher.appointment.id}/info`}
                >
                  Ver Marcação <ArrowRight style={{ marginLeft: 5 }} size={23} />
                </Button>
              </InfosGrid>
            </>
          )}
        </BottomGrid>
      </Grid>
    </Container>
  )
}

export default VoucherInfo
