import { Grid } from '@material-ui/core'
import styled from 'styled-components'

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px;
  display: flex;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
`
