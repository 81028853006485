import React, { useState, useEffect } from 'react'

import {
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  Tooltip,
  makeStyles,
  Container,
} from '@material-ui/core'
import { ToggleButton } from '@material-ui/lab'
import { GridAlt } from '@styled-icons/boxicons-regular/GridAlt'
import { Menu } from '@styled-icons/boxicons-regular/Menu'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import UserCard from 'components/Users/Card'
import UserTable from 'components/Users/Table'
import { ContainerCard } from 'pages/styles'

import {
  DownStat,
  Percentage,
  StatusDot,
  StyledContainerCard,
  StyledTextField,
  StyledToggleButtonGroup,
  UpStat,
} from './styles'

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    padding: 10,
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))

const UsersList = () => {
  const classes = useTooltipStyles()
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')
  const [view, setView] = useState('card')
  const [loading, setLoading] = useState(true)
  const [statistics, setStatistics] = useState(null)

  useEffect(() => {
    api.get('/users').then(({ data }) => {
      setUsers(data.users)
      setStatistics(data.statistics)
      setLoading(false)
    })
  }, [])

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        justify="space-evenly"
        style={{ margin: '30px 0', padding: '0 20px' }}
      >
        <StyledContainerCard container justify="space-between" item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Total de clientes
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4">{statistics?.total.count}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Percentage percentage={statistics?.total.percentage} variant="body1">
                {statistics?.total.percentage}%
              </Percentage>
              {statistics?.total.percentage < 100 && <DownStat size={25} />}
              {statistics?.total.percentage > 100 && <UpStat size={25} />}
            </div>
          </Grid>
        </StyledContainerCard>
        <StyledContainerCard container alignItems="stretch" item xs={12} sm={5} md={3}>
          <Typography variant="subtitle2" color="textSecondary">
            Estados das contas
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            <Tooltip classes={classes} placement="top" arrow title="Confirmado">
              <Grid item>
                <Grid container alignItems="center">
                  <StatusDot status="true" />
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {statistics?.status?.confirmed.count}
                  </Typography>
                </Grid>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Percentage
                    percentage={statistics?.status?.confirmed.percentage}
                    variant="body2"
                  >
                    {statistics?.status?.confirmed.percentage}%
                  </Percentage>
                  {statistics?.status?.confirmed.percentage < 100 && (
                    <DownStat size={20} />
                  )}
                  {statistics?.status?.confirmed.percentage > 100 && <UpStat size={20} />}
                </div>
              </Grid>
            </Tooltip>
            <Tooltip classes={classes} placement="top" arrow title="Pendente">
              <Grid item>
                <Grid container alignItems="center">
                  <StatusDot status="false" />
                  <Typography variant="body1" style={{ fontWeight: 600 }}>
                    {statistics?.status?.pending.count}
                  </Typography>
                </Grid>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Percentage
                    percentage={statistics?.status?.pending.percentage}
                    invert="true"
                    variant="body2"
                  >
                    {statistics?.status?.pending.percentage}%
                  </Percentage>
                  {statistics?.status?.pending.percentage < 100 && (
                    <DownStat invert="true" size={20} />
                  )}
                  {statistics?.status?.pending.percentage > 100 && (
                    <UpStat invert="true" size={20} />
                  )}
                </div>
              </Grid>
            </Tooltip>
          </Grid>
        </StyledContainerCard>
        <ContainerCard
          container
          direction="column"
          justify="space-around"
          item
          xs={12}
          sm={6}
          md={4}
          style={{ borderRadius: 3, height: 130, alignItems: 'flex-start' }}
        >
          <Typography variant="subtitle2" color="textSecondary">
            Cliente com mais visitas
          </Typography>
          <Typography variant="h6">{statistics?.user}</Typography>
        </ContainerCard>
      </Grid>
      <Grid container direction="row" alignItems="center" justify="space-between">
        <Grid item xs="auto" sm={8} md={6} style={{ flex: 1 }}>
          <StyledTextField
            placeholder="Procura pelo nome"
            variant="filled"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={27} />
                </InputAdornment>
              ),
              endAdornment: search.trim().length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch('')} label="clear-search">
                    <CloseOutline size={27} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs="auto" sm={4} md={6} style={{ textAlign: 'right', marginLeft: 15 }}>
          <StyledToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="change-view"
          >
            <ToggleButton value="card" aria-label="card">
              <GridAlt size={24} />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table">
              <Menu size={28} />
            </ToggleButton>
          </StyledToggleButtonGroup>
        </Grid>
      </Grid>
      {view === 'card' ? (
        <Grid container spacing={4} style={{ paddingTop: 20 }}>
          {users
            .filter(
              (usr) =>
                search.trim().length <= 0 ||
                usr.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((user) => (
              <UserCard key={user.id} user={user} />
            ))}
        </Grid>
      ) : (
        <UserTable users={users} search={search} />
      )}
    </Container>
  )
}

export default UsersList
