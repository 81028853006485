import { TextField, Grid, Typography } from '@material-ui/core'
import { ToggleButtonGroup } from '@material-ui/lab'
import { ArrowDownShort } from '@styled-icons/bootstrap/ArrowDownShort'
import { ArrowUpShort } from '@styled-icons/bootstrap/ArrowUpShort'
import { ContainerCard } from 'pages/styles'
import styled from 'styled-components'

const parsePercentageColor = (theme, invert = 'false', percentage) => {
  if (percentage < 100) {
    return invert === 'true' ? theme.palette.success.main : theme.palette.error.main
  }
  if (percentage === 100) {
    return theme.palette.info.main
  }
  if (percentage > 100) {
    return invert === 'true' ? theme.palette.error.main : theme.palette.success.main
  }
  return theme.palette.text.primary
}

export const StyledTextField = styled(TextField)`
  & .MuiFilledInput-root {
    height: 54px;
    & fieldset {
      border: 0;
    }

    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }

  & .MuiFilledInput-input {
    padding: 10px 12px 10px 0px;
  }

  & .MuiInputAdornment-positionStart {
    margin-top: 5px !important;
  }

  & .Mui-focused {
    background-color: ${({ theme }) => theme.palette.background.paper};
    & fieldset {
      border: 1px solid black;
    }
    & svg {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  & svg {
    color: ${({ theme }) => theme.palette.grey[400]};
  }
`

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  & button {
    border-width: 0px;
    border-radius: 3px !important;
    padding: 12px 15px;

    &:hover {
      background-color: transparent;
      color: ${({ theme }) => theme.palette.primary.main};
    }

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.primary.background};
      color: ${({ theme }) => theme.palette.primary.main};

      &:hover {
        background-color: ${({ theme }) => theme.palette.primary.background};
      }
    }
  }
`

export const SearchInputGrid = styled(Grid)`
  ${({ theme }) => `
    ${theme.breakpoints.up('sm')} {
      margin-left: 25px;
      flex: 1;
    }
  `};
`

export const StyledContainerCard = styled(ContainerCard)`
  border-radius: 3px;
  height: 130px;

  ${({ theme }) => `
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 20px;
    }
  `};
`

export const DownStat = styled(ArrowDownShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.success.main : theme.palette.error.main};
  margin-left: 5px;
`

export const UpStat = styled(ArrowUpShort)`
  color: ${({ theme, invert }) =>
    invert === 'true' ? theme.palette.error.main : theme.palette.success.main};
  margin-left: 5px;
`

export const Percentage = styled(Typography)`
  color: ${({ percentage, invert, theme }) =>
    parsePercentageColor(theme, invert, percentage)};
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
`

export const StatusDot = styled.div`
  background-color: ${({ status, theme }) =>
    status === 'true' ? theme.palette.success.main : theme.palette.warning.main};
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
`
