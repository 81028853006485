import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const parseStatusColor = (status) => {
  switch (status) {
    case 'confirm-guest':
      return `
        background-color: #ffd700;
        color: #22242C;

        & .service {
          opacity: 0.7;
        }
      `
    case 'pending':
      return `
        background-color: #ff9800;
        color: #22242C;

        & .service {
          opacity: 0.7;
        }
      `
    case 'confirmed':
      return `
        background-color: #2196f3;
        color: #22242C;

        & .service {
          opacity: 0.7;
        }
      `
    case 'paid':
      return `
        background-color: #89AB15;
        color: #22242C;

        & .service {
          opacity: 0.7;
        }
      `
    case 'finished':
      return `
        background-color: #4caf50;
        color: #22242C;

        & .service {
          opacity: 0.7;
        }
      `
    case 'canceled':
      return `
        background-color: #f44336;
        color: #FFF;

        & .service {
          opacity: 0.7;
        }
      `
    case 'expired':
      return `
        background-color: #d32f2f;
        color: #FFF;

        & .service {
          opacity: 0.7;
        }
      `
    case 'absent':
      return `
        background-color: #E91E63;
        color: #FFF;

        & .service {
          opacity: 0.7;
        }
      `
    default:
      return ''
  }
}

export const Dot = styled.span`
  width: 5px;
  height: 5px;
  background-color: #22242c;
  border-radius: 50%;
  margin: 0 10px;
`

export const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
`

const TimePassed = styled(Grid)`
  width: 50px;
  height: 50px;
  padding: 5px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
  font-weight: 500;
  font-size: 0.7rem;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.menu.text};
`

const NewApptContainer = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;
  margin-bottom: 15px;
  transition: opacity 200ms ease;

  &:hover {
    opacity: 0.8;
  }

  ${({ status }) => status && parseStatusColor(status)}
`

export const NewAppt = ({ appointment }) => (
  <NewApptContainer container alignItems="center" status={appointment.status}>
    <Grid item xs="auto">
      <TimePassed container justify="center" alignItems="center">
        {moment(appointment.created_at, 'DD/MM/YYYY HH:mm').fromNow()}
      </TimePassed>
    </Grid>
    <Grid item xs="auto" style={{ flex: 1, textAlign: 'left' }}>
      <Typography variant="subtitle2" className="service">
        {appointment.service.name}
      </Typography>
      <Typography variant="subtitle2" style={{ margin: '5px 0' }}>
        {appointment.user?.name || appointment.user_name}
      </Typography>
      <Grid container alignItems="center">
        <Typography variant="body2" style={{ textTransform: 'capitalize' }}>
          {moment(appointment.date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY')}
        </Typography>
        <Dot />
        <Typography variant="body2">
          {`${appointment.start_time.slice(
            0,
            appointment.start_time.length - 3
          )} - ${appointment.end_time.slice(0, appointment.end_time.length - 3)}`}
        </Typography>
        <Dot />
        <Typography variant="body2">{appointment.price} €</Typography>
      </Grid>
    </Grid>
  </NewApptContainer>
)

NewAppt.propTypes = {
  appointment: PropTypes.object.isRequired,
}

export const NewApptsGrid = styled(Grid)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
  margin-top: 10px;
  max-height: 330px;
  overflow-y: auto;
`
