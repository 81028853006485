import React, { useEffect, useState } from 'react'

import { Grid, Typography } from '@material-ui/core'
import { api } from 'api'
import styled from 'styled-components'

const StyledCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: 15px 20px;
  display: flex;
  min-height: 80px;
  border-radius: 10px;

  & h3,
  & h6 {
    color: white;
  }

  ${({ type, theme }) =>
    type === 'first'
      ? `
    background-color: #3D9F48;
  `
      : type === 'second'
      ? `
    background-color: #008C68;
  `
      : `
    background-color: #007777;
  `}
`

const UsersQuickStats = () => {
  const [loading, setLoading] = useState(false)
  const [stats, setStats] = useState({ clients: 0, guests: 0 })

  useEffect(() => {
    setLoading(true)
    api.get('/users/stats').then(({ data }) => {
      setLoading(false)
      setStats(data)
    })
  }, [])

  return (
    <>
      <Grid item>
        <StyledCard type="first">
          <Grid container direction="column">
            <Typography variant="h3" color="secondary">
              {loading ? '...' : stats.clients}
            </Typography>
            <Typography variant="subtitle2" color="secondary">
              Registos este mês
            </Typography>
          </Grid>
        </StyledCard>
      </Grid>
      <Grid item>
        <StyledCard type="second">
          <Grid container direction="column">
            <Typography variant="h3" color="secondary">
              {loading ? '...' : stats.guests}
            </Typography>
            <Typography variant="subtitle2" color="secondary">
              Novos convidados este mês
            </Typography>
          </Grid>
        </StyledCard>
      </Grid>
    </>
  )
}

export default UsersQuickStats
