import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import VoucherInfo from './Info'
import VouchersList from './List'
import VoucherTemplates from './Templates'

const Vouchers = () => {
  return (
    <Switch>
      <Route path="/vouchers/:id/info">
        <VoucherInfo />
      </Route>
      <Route path="/vouchers/templates">
        <VoucherTemplates />
      </Route>
      <Route exact path="/vouchers">
        <VouchersList />
      </Route>
      <Route path="*">
        <Redirect to="/404" />
      </Route>
    </Switch>
  )
}

export default Vouchers
