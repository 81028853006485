import { Grid, Typography, Link, Select } from '@material-ui/core'
import { ReactComponent as VoucherTitle } from 'assets/voucher-title.svg'
import styled from 'styled-components'

export const Title = styled(VoucherTitle)`
  width: 500px;
  height: 500px;
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      width: 90%;
      height: 90%;
    }
  `};
`

export const Theme = styled.div`
  background-position: center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 3px;
  margin-top: 15px;
  overflow: hidden;
  box-shadow: 0px 10px 15px -5px rgba(137, 171, 21, 0.15);
  ${({ theme }) => `
    ${theme.breakpoints.down('xs')} {
      height: 150px;
    }
  `};
`

export const MiddleGrid = styled(Grid)`
  padding: 20px 0;
`

export const BottomGrid = styled(Grid)`
  padding: 20px 0;
  border-radius: 3px;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`

export const ServiceName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightSemiBold};
  margin-bottom: 10px;
`

export const Hr = styled.hr`
  width: 70px;
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  margin: 0;
`

export const InfosGrid = styled(Grid)`
  margin-top: 25px;

  & .MuiTypography-subtitle2 {
    font-weight: 400;
    margin-right: 10px;
  }

  & .MuiTypography-subtitle1 {
    font-weight: 600;
    letter-spacing: 0.03rem;
  }
`

export const CodeNumber = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.default};
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 10px;
`

export const UserGrid = styled(Grid)`
  padding: 10px 15px;
  height: 100%;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.background.paper};
`

export const StyledLink = styled(Link)`
  cursor: pointer;
`

export const StyledSelect = styled(Select)`
  width: 200px;
`
