import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import {
  Container,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ChevronRight } from '@styled-icons/boxicons-regular/ChevronRight'
import { Search } from '@styled-icons/boxicons-regular/Search'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { api } from 'api'
import LoadingPage from 'components/shared/LoadingPage'
import VouchersTable from 'components/Vouchers/Table'
import parseVoucherStatus from 'helpers/parse-voucher-status'
import { ContainerCard } from 'pages/styles'

import {
  DownStat,
  Percentage,
  StatusDot,
  StyledContainerCard,
  StyledTextField,
  TemplateLink,
  UpStat,
} from './styles'

const useTooltipStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.background.paper,
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    padding: 10,
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}))

const VouchersList = () => {
  const classes = useTooltipStyles()
  const [search, setSearch] = useState('')
  const [vouchers, setVouchers] = useState([])
  const [loading, setLoading] = useState(true)
  const [statistics, setStatistics] = useState(null)

  useEffect(() => {
    api.get('/vouchers').then(({ data }) => {
      setVouchers(data.vouchers)
      let status = data.statistics?.status
      if (status?.length > 0) {
        const mapped = ['pending', 'paid', 'scheduled', 'canceled']
        let sorted = []
        for (let i = 0; i < mapped.length; i++) {
          const index = status.findIndex((st) => st.status === mapped[i])
          if (index === -1) {
            sorted[i] = { status: mapped[i], count: 0 }
          } else {
            sorted[i] = status[index]
          }
        }
        const stats = { ...data.statistics }
        stats.status = sorted
        setStatistics(stats)
      } else {
        setStatistics(data.statistics)
      }
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <LoadingPage />
  }

  return (
    <Container maxWidth="lg" style={{ marginTop: 20 }}>
      <Grid
        container
        justify="space-evenly"
        style={{ margin: '30px 0', padding: '0 20px' }}
      >
        <StyledContainerCard container justify="space-between" item xs={12} sm={6} md={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Total de vouchers
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h4">{statistics?.total.count}</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Percentage percentage={statistics?.total.percentage} variant="body1">
                {statistics?.total.percentage}%
              </Percentage>
              {statistics?.total.percentage < 100 && <DownStat size={25} />}
              {statistics?.total.percentage > 100 && <UpStat size={25} />}
            </div>
          </Grid>
        </StyledContainerCard>
        <StyledContainerCard container alignItems="stretch" item xs={12} sm={5} md={4}>
          <Typography variant="subtitle2" color="textSecondary">
            Estados dos vouchers
          </Typography>
          <Grid container justify="space-between" alignItems="center">
            {statistics?.status.map((status) => (
              <Tooltip
                classes={classes}
                placement="top"
                arrow
                title={parseVoucherStatus(status.status)}
                key={status.status}
              >
                <Grid item>
                  <Grid container alignItems="center">
                    <StatusDot status={status.status} />
                    <Typography variant="body1" style={{ fontWeight: 600 }}>
                      {status.count}
                    </Typography>
                  </Grid>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Percentage
                      percentage={status.percentage}
                      invert={
                        status.status === 'pending' || status.status === 'canceled'
                          ? 'true'
                          : ''
                      }
                      variant="body2"
                    >
                      {status.percentage}%
                    </Percentage>
                    {status.percentage < 100 && (
                      <DownStat
                        invert={
                          status.status === 'pending' || status.status === 'canceled'
                            ? 'true'
                            : ''
                        }
                        size={20}
                      />
                    )}
                    {status.percentage > 100 && (
                      <UpStat
                        invert={
                          status.status === 'pending' || status.status === 'canceled'
                            ? 'true'
                            : ''
                        }
                        size={20}
                      />
                    )}
                  </div>
                </Grid>
              </Tooltip>
            ))}
          </Grid>
        </StyledContainerCard>
        <ContainerCard
          container
          direction="column"
          justify="space-around"
          item
          xs={12}
          sm={6}
          md={3}
          style={{
            borderRadius: 3,
            height: 130,
            alignItems: 'flex-start',
            padding: 0,
          }}
        >
          <TemplateLink component={Link} to={`/vouchers/templates`}>
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                Templates de
              </Typography>
              <Typography variant="h5">Vouchers</Typography>
            </div>
            <ChevronRight size={25} />
          </TemplateLink>
        </ContainerCard>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledTextField
            placeholder="Procura pelo nome do destinatário"
            variant="filled"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search size={27} />
                </InputAdornment>
              ),
              endAdornment: search.trim().length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={() => setSearch('')} label="clear-search">
                    <CloseOutline size={27} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <VouchersTable vouchers={vouchers} search={search} />
    </Container>
  )
}

export default VouchersList
