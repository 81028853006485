import React from 'react'
import { useHistory } from 'react-router-dom'

import { Typography } from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'

import { TimelineWrapper } from './styles'

const Timeline = ({ appointments }) => {
  const history = useHistory()
  const parseColor = (status) => {
    switch (status) {
      case 'pending':
        return 'warning'
      case 'confirmed':
        return 'yellow'
      case 'paid':
        return 'info'
      case 'finished':
        return 'success'
      case 'canceled':
        return 'error'
      case 'absent':
        return 'pink'
      default:
        return ''
    }
  }

  return (
    <TimelineWrapper>
      {appointments.map((appointment, index) => (
        <li
          key={index}
          onClick={() => history.push(`/appointments/${appointment.id}/info`)}
        >
          <div className={`bullet ${parseColor(appointment.status)}`}></div>
          <div className="time">
            <p style={{ textTransform: 'capitalize' }}>
              {moment(appointment.date).format('D MMM YY')}
            </p>
            <span>{moment(appointment.start_time, 'HH:mm:ss').format('HH:mm')}</span>
          </div>
          <div className="desc">
            <Typography variant="subtitle2">{appointment.service.name}</Typography>
          </div>
        </li>
      ))}
    </TimelineWrapper>
  )
}

Timeline.propTypes = {
  appointments: PropTypes.array,
}
Timeline.defaultProps = {
  appointments: [],
}

export default Timeline
