import React, { useState, lazy, Suspense } from 'react'

import { Container, Tab, Tabs } from '@material-ui/core'
import StatisticsSkeleton from 'components/Appointments/Statistics/LoadingSkeleton'
import LoadingPage from 'components/shared/LoadingPage'
import { ContainerCard } from 'pages/styles'

const Statistics = lazy(() => import('components/Appointments/Statistics'))
const Scheduler = lazy(() => import('components/Appointments/Scheduler'))
const AppointmentsTable = lazy(() => import('components/Appointments/Table'))

const AppointmentsList = () => {
  const [currentTab, setCurrentTab] = useState('calendar')

  const handleChange = (e, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <Container maxWidth="lg" style={{ padding: 0 }}>
      <Suspense fallback={<StatisticsSkeleton />}>
        <Statistics />
      </Suspense>
      <ContainerCard style={{ padding: 0, minHeight: 60 }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          style={{ height: 60, paddingTop: 5 }}
        >
          <Tab
            style={{ textTransform: 'none' }}
            disableRipple
            disableFocusRipple
            label="Calendário"
            value="calendar"
          />
          <Tab
            style={{ textTransform: 'none' }}
            disableRipple
            disableFocusRipple
            label="Lista"
            value="list"
          />
        </Tabs>
      </ContainerCard>
      <Suspense fallback={<LoadingPage />}>
        {currentTab === 'calendar' && <Scheduler />}
        {currentTab === 'list' && <AppointmentsTable />}
      </Suspense>
    </Container>
  )
}

export default AppointmentsList
